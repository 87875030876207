/* Pages */

import { Redirect, Route, Switch } from "react-router-dom";

import { AppSummaryPage } from "manager/components/app-summary";
import Layout from "manager/components/Layout";
import { APPLICANT, APPLICATION, PORTFOLIO, UNIT } from "manager/config/mocks";
import ROUTES from "manager/config/routes";
import { getBackLinks, getHeader, getSidebar } from "manager/hooks/use-layout";
import { useManagerTranslate } from "manager/hooks/use-manager-translate";
import Applicant from "manager/pages/Applicant";
import Application from "manager/pages/Application";
import ApplicationLeaseSetup from "manager/pages/ApplicationLeaseSetup";
import Dashboard from "manager/pages/Dashboard";
import DealExtraDetails from "manager/pages/DealExtraDetails";
import Deals from "manager/pages/Deals";
import DownloadCenter from "manager/pages/DownloadCenter";
import EmailBuilder from "manager/pages/EmailBuilder";
import Employee from "manager/pages/Employee";
import Employees from "manager/pages/Employees";
import ExperianAuth from "manager/pages/ExperianAuth";
import { isExperianProvider } from "manager/pages/ExperianAuth/utils";
import Leasing from "manager/pages/Leasing";
import { LumenRoutes } from "manager/pages/Lumen";
import MagicLinks from "manager/pages/MagicLinks";
import { useGetMarketplaceRoutes } from "manager/pages/Marketplace/use-marketplace-routes";
import OpenApiSettings from "manager/pages/OpenApiSettings";
import PasswordRecoverySetPassword from "manager/pages/PasswordRecoverySetPassword";
import Portfolio from "manager/pages/Portfolio";
import PortfolioSettings from "manager/pages/PortfolioSettings";
import {
  CompanyPrescreeningSettings,
  LeadDetails,
  Leads,
} from "manager/pages/Prescreening";
import Properties from "manager/pages/Properties";
import PropertySettings from "manager/pages/PropertySettings";
import SetupAllFields from "manager/pages/PropertySettings/SetupAllFields";
import Renewals from "manager/pages/Renewals";
import SelectCompany from "manager/pages/SelectCompany";
import SetPassword from "manager/pages/SetPassword";
import Settings from "manager/pages/Settings";
import Unit from "manager/pages/Unit";

import App from "shared/components/App";
import { RenderModal } from "shared/components/Modal";
import { APPS } from "shared/config/constants";
import Error404Page from "shared/pages/Error404Page";
import PasswordRecoveryEmailSentPage from "shared/pages/PasswordRecoveryEmailSentPage";
import PasswordRecoveryPage from "shared/pages/PasswordRecoveryPage";
import SetPasswordLinkAcceptedPage from "shared/pages/SetPasswordLinkAcceptedPage";
import SetPasswordLinkExpiredPage from "shared/pages/SetPasswordLinkExpiredPage";
import SetPasswordLinkSentPage from "shared/pages/SetPasswordLinkSentPage";
import { ConditionalPage, ConditionalRoute } from "shared/utils/routing";

/* Utils */

type ManagerAppProps = {
  company?: {
    name?: string;
    shortName?: string;
    logo?: string;
    slug: string;
  };
  userExperianAuthenticated?: boolean;
  hasPermission: (permission: any) => boolean;
  permissionsLoaded: boolean;
  isMagicLinksEnabled?: boolean;
  isOpenApiEnabled: boolean;
  isEmailCustomizationEnabled: boolean;
  isPrescreeningEnabled?: boolean;
  areRenewalsEnabled?: boolean;
};

const experianPrompt = (company, userExperianAuthenticated) => {
  return isExperianProvider(company) && !userExperianAuthenticated;
};

const ExperianProtected = (props) => {
  const { company, userExperianAuthenticated, permissionsLoaded, path } = props;
  if (
    company === undefined ||
    !permissionsLoaded ||
    (company && !experianPrompt(company, userExperianAuthenticated))
  ) {
    return <Route {...props} />;
  }

  return (
    <Redirect
      from={path}
      to={{
        pathname: ROUTES.dashboard,
        state: { experianPrompt: true },
      }}
    />
  );
};

const ManagerApp = ({
  company,
  userExperianAuthenticated = false,
  hasPermission,
  permissionsLoaded,
  isMagicLinksEnabled = false,
  isOpenApiEnabled,
  isEmailCustomizationEnabled,
  isPrescreeningEnabled = false,
  areRenewalsEnabled = false,
}: ManagerAppProps) => {
  const { t } = useManagerTranslate();

  const { getMarketplaceRoutes } = useGetMarketplaceRoutes();

  return (
    <App
      id={APPS.manager}
      render={({ history, user }) => {
        const sidebar = getSidebar(
          t,
          // @ts-ignore
          company,
          isPrescreeningEnabled,
          areRenewalsEnabled
        );
        const header = getHeader(user, isMagicLinksEnabled);
        const backLinks = getBackLinks(t);
        const layoutProps = {
          user,
          company,
          isMagicLinksEnabled,
          areRenewalsEnabled,
          isPrescreeningEnabled,
        };

        return (
          <>
            <Switch>
              <Route
                exact
                path={ROUTES.dashboard}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                      backLink: backLinks.dashboard,
                    }}
                  >
                    <Dashboard backLink={backLinks.dashboard} />
                  </Layout>
                )}
              />
              {/* Table views */}
              <Route
                exact
                path={ROUTES.leads}
                render={(params) => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                    }}
                  >
                    <ConditionalPage
                      params={params}
                      isInitialized={permissionsLoaded}
                      isEnabled={isPrescreeningEnabled}
                    >
                      <Leads />
                    </ConditionalPage>
                  </Layout>
                )}
              />
              <Route
                exact
                path={ROUTES.deals}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                    }}
                  >
                    <Deals
                      experianPrompt={experianPrompt(
                        company,
                        userExperianAuthenticated
                      )}
                    />
                  </Layout>
                )}
              />
              <ExperianProtected
                permissionsLoaded={permissionsLoaded}
                company={company}
                userExperianAuthenticated={userExperianAuthenticated}
                path={ROUTES.deal}
                // @ts-ignore
                render={() => <AppSummaryPage {...layoutProps} />}
              />
              <Route
                exact
                path={ROUTES.employees}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                      backLink: backLinks.employees,
                    }}
                  >
                    <Employees backLink={backLinks.employees} />
                  </Layout>
                )}
              />
              {[ROUTES.employees, ROUTES.permissions].map((route) => (
                <Route
                  key={route}
                  exact
                  path={route}
                  render={() => (
                    <Layout
                      sidebar={sidebar}
                      header={{
                        ...header,
                        backLink: backLinks.employees,
                      }}
                    >
                      <Employees backLink={backLinks.employees} />
                    </Layout>
                  )}
                />
              ))}
              {[ROUTES.units, ROUTES.properties, ROUTES.portfolios].map(
                (route) => (
                  <Route
                    key={route}
                    exact
                    path={route}
                    render={() => (
                      <Layout sidebar={sidebar} header={header}>
                        <Properties />
                      </Layout>
                    )}
                  />
                )
              )}
              <Route
                path={ROUTES.applicationsLeaseSetup}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                      backLink: backLinks.applicationsLeaseSetup,
                    }}
                  >
                    <ApplicationLeaseSetup
                      backLink={backLinks.applicationsLeaseSetup}
                    />
                  </Layout>
                )}
              />
              <Route
                path={ROUTES.prescreeningSetup}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                      backLink: backLinks.prescreeningSetup,
                    }}
                  >
                    <CompanyPrescreeningSettings
                      backLink={backLinks.prescreeningSetup}
                    />
                  </Layout>
                )}
              />
              {/* Other views */}
              <Route
                path={ROUTES.lead}
                render={(params) => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                      backLink: backLinks.lead,
                    }}
                    withoutBackToTop
                  >
                    <ConditionalPage
                      params={params}
                      isInitialized={permissionsLoaded}
                      isEnabled={isPrescreeningEnabled}
                    >
                      <LeadDetails backLink={backLinks.lead} />
                    </ConditionalPage>
                  </Layout>
                )}
              />
              <Route
                exact
                path={ROUTES.dealExtraDetails}
                render={({
                  match: {
                    params: { id },
                  },
                }) => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                      backLink: backLinks.dealExtraDetails(id),
                    }}
                    withoutBackToTop
                  >
                    <DealExtraDetails
                      backLink={backLinks.dealExtraDetails(id)}
                    />
                  </Layout>
                )}
              />
              <Route
                path={ROUTES.leases}
                // @ts-ignore
                render={() => <Leasing sidebar={sidebar} header={header} />}
              />
              <Route
                path={ROUTES.renewals}
                // @ts-ignore
                render={() => <Renewals sidebar={sidebar} header={header} />}
              />
              <ExperianProtected
                permissionsLoaded={permissionsLoaded}
                company={company}
                userExperianAuthenticated={userExperianAuthenticated}
                path={ROUTES.application}
                render={({ match }) => (
                  <Application
                    sidebar={sidebar}
                    header={header}
                    {...APPLICATION}
                    backLink={backLinks.application}
                    match={match}
                  />
                )}
              />
              <Route
                path={ROUTES.propertySettingsSetupFields}
                render={({
                  match: {
                    params: { id },
                  },
                }) => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                      backLink: backLinks.propertyDocumentSetup(id),
                    }}
                  >
                    <SetupAllFields
                      backLink={backLinks.propertyDocumentSetup(id)}
                    />
                  </Layout>
                )}
              />
              <Route
                path={ROUTES.property}
                render={({
                  match: {
                    params: { id },
                  },
                }) => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                      backLink: backLinks.propertySettings(id),
                    }}
                  >
                    <PropertySettings
                      backLink={backLinks.propertySettings(id)}
                      // @ts-ignore
                      sidebar={sidebar}
                      header={header}
                    />
                  </Layout>
                )}
              />
              <Route
                exact
                path={ROUTES.portfolio}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                      backLink: backLinks.portfolio,
                    }}
                  >
                    <Portfolio {...PORTFOLIO} backLink={backLinks.portfolio} />
                  </Layout>
                )}
              />
              <Route
                path={ROUTES.portfolioSettings}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                      backLink: backLinks.portfolio,
                    }}
                  >
                    <PortfolioSettings backLink={backLinks.portfolio} />
                  </Layout>
                )}
              />
              <Redirect exact from={ROUTES.unit} to={ROUTES.unitListings} />
              <Route
                path={[ROUTES.unitListings, ROUTES.unitDeals, ROUTES.unitFees, ROUTES.unitWorkflow, ROUTES.unitLumen]}
                render={(routeProps) => (
                  <Unit
                    {...UNIT}
                    //  @ts-ignore
                    sidebar={sidebar}
                    //  @ts-ignore
                    header={header}
                    id={routeProps?.match?.params?.id}
                    company={company}
                  />
                )}
              />
              <Route
                exact
                path={ROUTES.employee}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                      backLink: backLinks.employee,
                    }}
                  >
                    <Employee backLink={backLinks.employee} />
                  </Layout>
                )}
              />
              <Route
                exact
                path={ROUTES.applicant}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                      backLink: backLinks.applicant,
                    }}
                  >
                    <Applicant
                      {...APPLICANT}
                      history={history}
                      backLink={backLinks.applicant}
                    />
                  </Layout>
                )}
              />
              {getMarketplaceRoutes(
                sidebar,
                header,
                backLinks,
                permissionsLoaded,
                hasPermission,
                company
              )}
              <Route
                path={ROUTES.settings}
                render={() => (
                  <Layout sidebar={sidebar} header={header}>
                    <Settings />
                  </Layout>
                )}
              />
              <Route
                path={ROUTES.magicLinks}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                      backLink: backLinks.magicLinks,
                    }}
                  >
                    <MagicLinks backLink={backLinks.magicLinks} />
                  </Layout>
                )}
              />
              <Route
                path={ROUTES.downloadCenter}
                render={() => (
                  <Layout
                    sidebar={sidebar}
                    header={{
                      ...header,
                      backLink: backLinks.downloadCenter,
                    }}
                  >
                    <DownloadCenter backLink={backLinks.downloadCenter} />
                  </Layout>
                )}
              />
              <ConditionalRoute
                path={ROUTES.openApiSettings}
                redirectTo={ROUTES.notFound}
                isEnabled={isOpenApiEnabled}
                isInitialized={permissionsLoaded}
              >
                <Layout
                  sidebar={sidebar}
                  header={{
                    ...header,
                    backLink: backLinks.openApiSettings,
                  }}
                >
                  <OpenApiSettings backLink={backLinks.openApiSettings} />
                </Layout>
              </ConditionalRoute>

              <Route
                path={ROUTES.lumen}
                render={() => (
                  <LumenRoutes
                    // @ts-ignore
                    header={header}
                    // @ts-ignore
                    backLinks={backLinks}
                    // @ts-ignore
                    sidebar={sidebar}
                  />
                )}
              />

              <ConditionalRoute
                exact
                path={[ROUTES.emailBuilder, ROUTES.emailBuilderEdit]}
                isEnabled={isEmailCustomizationEnabled}
                isInitialized={permissionsLoaded}
                redirectTo={ROUTES.notFound}
              >
                <EmailBuilder header={header} sidebar={sidebar} />
              </ConditionalRoute>

              {/* Auth views */}
              <Route
                exact
                path={ROUTES.setPassword}
                render={() => <SetPassword />}
              />
              <Route
                exact
                path={ROUTES.setPasswordLinkExpired}
                render={({ match }) => (
                  <SetPasswordLinkExpiredPage token={match.params.token} />
                )}
              />
              <Route
                exact
                path={ROUTES.setPasswordLinkSent}
                render={() => <SetPasswordLinkSentPage />}
              />
              <Route
                exact
                path={ROUTES.setPasswordLinkAccepted}
                render={() => <SetPasswordLinkAcceptedPage />}
              />
              <Route
                exact
                path={ROUTES.passwordRecovery}
                render={() => <PasswordRecoveryPage />}
              />
              <Route
                exact
                path={ROUTES.passwordRecoveryEmailSent}
                render={({ location }) => (
                  // @ts-ignore
                  <PasswordRecoveryEmailSentPage email={location.state.email} />
                )}
              />
              <Route
                exact
                path={ROUTES.passwordRecoverySetPassword}
                render={() => <PasswordRecoverySetPassword />}
              />
              <Route
                exact
                path={ROUTES.selectCompany}
                render={() => <SelectCompany />}
              />
              <Route
                exact
                path={ROUTES.experianAuthentication}
                render={() => <ExperianAuth company={company} />}
              />
              {/* Other views */}
              <Route render={() => <Error404Page />} />
            </Switch>
            {/* Modals */}
            <RenderModal />
          </>
        );
      }}
    />
  );
};

export default ManagerApp;
